import { ToastContainer } from "react-toastify";
import "./assets/scss/App.scss";
import Routers from "./router/router";
import { useEffect } from "react";

const App = () => {

  useEffect(() => {
    let url = window.location.href;
    if (url === 'https://zignuts.dev/purplept/webapp/clinician/dashboard?confirmedshifts=1') {
      window.location = url.replace('https://zignuts.dev/purplept/webapp', 'https://purpleprn.com')
    }
  }, [])

  return (
    <div className="App">
      <Routers />
      <ToastContainer newestOnTop />
    </div>
  );
}

export default App;
